var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "claims-summary" },
              [
                _c(
                  "download-csv",
                  { attrs: { data: _vm.exportData, name: _vm.csvFilename } },
                  [
                    _c(
                      "el-button",
                      { staticClass: "item export", attrs: { size: "mini" } },
                      [_vm._v(" Export CSV ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "item total",
                    attrs: { disabled: "", size: "mini", type: "primary" },
                  },
                  [
                    _vm._v(
                      " Total due: " +
                        _vm._s(_vm._f("currency")(_vm.total)) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("claim-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading claims pending approval",
          css: { height: "calc(100vh - 200px)" },
          claims: _vm.claims,
          scroll: true,
          sort: _vm.sort,
          show_approvals: "",
        },
        on: { "sort-by": _vm.sortBy, "new-claim": _vm.newClaim },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }